import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar"
import Slider from "react-slick"
import Layout from "components/layout"
import SEO from "components/seo"
import MarkdownViewer from "@components/MarkdownViewer"
import { Section, Container } from "../components/Layouts"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Button from "../components/Buttons/Button"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { MarketingAnalysisOMS } from "../components/Forms"

function PlasticsMarketing({ location }) {
  const { href, pathname } = location

  let stars = []
  for (let i = 1; i <= 5; i++) {
    stars.push(<FontAwesomeIcon icon={faStar} key={i} />)
  }

  const anchorLink = "#cta-form/"

  const page = {
    metaTitle: "Plastic Surgery Marketing | Nuvolum",
    metaDescription:
      "Nuvolum’s proven formula helps plastic surgeons grow their practice and achieve their business goals. Learn more about Nuvolum’s plastic surgery marketing strategy and how it can bring success to your practice.",
    heroSection: {
      image: "NUVO/V3/email_blasts/dr-fan"
    }
  }
  const marketingSection = {
    content:
      "### Success Starts Here \n\n Drive more referrals, increase case acceptance, increase online visibility, expand to new offices, and more. Wherever your goals are, let Nuvolum take you there.",
    steps: [
      {
        top: "Request an Introductory Call/Analysis",
        bottom: "Tell us about your goals."
      },
      {
        top: "Get Your Custom Strategy",
        bottom: "We'll show you how to win your market."
      },
      {
        top: "Win With Nuvolum",
        bottom: "Let's get you where you want to be."
      }
    ],
    buttonText: "Request an Introductory Analysis/Call"
  }

  return (
    <Layout navSpacer language="en" path={pathname} className="marketing-blog">
      <SEO title={page.metaTitle} description={page.metaDescription} />

      <div className="ortho-marketing-page">
        <Section>
          <Container large className="has-text-centered">
            <MarkdownViewer markdown={"# Win Your Local Market"} />
          </Container>
        </Section>

        <Section>
          <Container large>
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column relative">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=IByzyHngSCo`}
                  language="en"
                  playing
                  controls
                  asWrapper
                  wrapperClassName="contained eb__watch">
                  <NuvoImage
                    cloudName="nuvolum"
                    publicId="NUVO/V3/email_blasts/dr-fan"
                    responsive
                    className="w-100"
                    width="auto"
                    useAR={false}
                  />
                  <div className="sm__play">
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                </TheaterVideo>
              </div>
              <div className="column is-4"></div>
            </div>
          </Container>
        </Section>

        <Section colorBack smallSpacing zeroBottom className="relative">
          <Container small>
            <MarketingAnalysisOMS
              id="cta-form"
              origin={href}
              phone="(415) 304-8425"
              heading="Request an introductory call/analysis to discover how Nuvolum takes you where you want to go."
            />
          </Container>
        </Section>

        <Section className="color-back work-together">
          <Container large>
            <div className="has-text-centered">
              <MarkdownViewer markdown={marketingSection.content} />
            </div>
            <Section>
              <div className="columns marketing-section">
                {marketingSection.steps.map((step, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="column marketing-section__steps">
                        <div className="circle">
                          <h6 id={`num-${i + 1}`}>{i + 1}</h6>
                        </div>
                        <h5>{step.top}</h5>
                        <p>{step.bottom}</p>
                      </div>
                      {i < 2 && (
                        <>
                          <div className="arrow is-hidden-mobile">
                            <span>&#10230;</span>
                          </div>
                          <div className="column is-1 is-hidden-tablet">
                            &nbsp;
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            </Section>
            <div className="columns is-centered form">
              <Button
                className="mx-auto"
                anchor
                rounded
                buttonText={marketingSection.buttonText}
                href={anchorLink}
              />
            </div>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default PlasticsMarketing
